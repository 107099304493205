import { Features } from "./types/features.type"

const features: Features = {
  config: {
    description: "Testing configuration panel",
    filters: [
      {
        env: ["test", "development"]
      }
    ]
  },
  ["onboarding.access.mobile"]: {
    description: "Assign access step in the add cardholder wizard (mobile)",
    filters: [{ env: ["development"] }]
  },
  ["onboarding.pdflist.mobile"]: {
    description: "Addtional details step in the add cardholder wizard (mobile)",
    filters: [{ env: ["development"] }]
  },
  ["onboarding.credentials.mobile"]: {
    description: "Issue credentials step in the add cardholder wizard (mobile)",
    filters: [{ env: ["development"] }]
  },
  ["onboarding.competencies.desktop"]: {
    description: "Assign competencies step in the add cardholder wizard (desktop)",
    filters: [{ env: [] }]
  },
  ["onboarding.competencies.mobile"]: {
    description: "Assign competencies step in the add cardholder wizard (mobile)",
    filters: [{ env: [] }]
  },
  ["onboarding.summary.mobile"]: {
    description: "Summary step in the add cardholder wizard (mobile)",
    filters: [{ env: ["development"] }]
  },
  themes: {
    description: "Show Appearance (Colour Scheme) in Settings",
    filters: [{ env: ["development", "test", "production"] }]
  },
  settings: {
    description: "Settings page",
    filters: [{ env: ["development", "test", "production"] }]
  },
  simpleSearch: {
    description: "Simple Search",
    filters: [{ env: ["development", "test", "production"] }]
  },
  deleteCardholder: {
    description: "Delete Cardholder",
    filters: [{ env: ["development", "test", "production"] }]
  },
  setUserCode: {
    description: "Set User Code",
    filters: [{ env: ["development", "test"] }]
  }
}

export default features
