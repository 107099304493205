import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes"
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError, retry } from "@reduxjs/toolkit/query/react"
import { ActivityCollection } from "components/Cardholder/Activity/ActivityTab"
import Cardholder, { CardholderField, CardholderWithThumbnail, Thumbnail } from "../common/types/api.cardholder.type"
import { Division } from "../common/types/api.division.type"
import { LinkWithIdAndName } from "../common/types/api.link.type"
import { PDFFields, PDFItem } from "../common/types/api.personalDataField.type"
import { Results } from "../common/types/api.search.type"
import { OperatorSettings, OperatorSettingsName, PatchOperatorSettings, PatchSiteSettings, SiteSettings, SiteSettingsName } from "../common/types/api.settings.type"
import { FTItemType, VerbId } from "../common/types/commandCentreTypes"
import { buildHeaders } from "../common/utils/apiCall"
import { appendQueryString } from "../common/utils/appendQueryString"
import Version, { KnownCommandCentreVersion } from "../common/utils/version"
import { selectCardholderAssignableDivisions, selectCommandCentreVersion, selectDivisionsUrl, selectOperatorUrl, selectProfileImageIds } from "./session.selectors"
import { RootState } from "./store"

type GetEventsArgs = { url?: string; relatedItem?: string; fields: string[]; previous: string; top?: number; next?: string }
type GetEventUpdateArgs = { url: string; ts?: number }

type GetCardholderArgs = {
  url?: string
  fields?: CardholderField[]
  sort?: string
  skip?: number
  top?: number
  next?: string

  // filters
  name?: string
  description?: string
  division?: string[]
  "cards.number"?: string
  accessZone?: string[]

  /**
   * The purpose of this is so that you can force a refetch, set this to some value and RTK Query will think it does not have a cached result for this query
   */
  tag?: string
} & { [key in `pdf_${number}`]?: string }

type GetCardholderThumbnailsArgs = {
  url?: string
  cardholderIds: string[]
  sourcePdfIds: string[]
  maxWidth?: number
  maxHeight?: number
}
export type GetCardholdersWithThumbnailsArgs = Omit<GetCardholderArgs, "url"> &
  Omit<GetCardholderThumbnailsArgs, "url" | "cardholderIds" | "sourcePdfIds"> &
  Partial<Pick<GetCardholderThumbnailsArgs, "sourcePdfIds">> & {
    getCardholdersUrl?: string
    getCardholderThumbnailsUrl?: string
  }

export type CanDoVerbResult = { isPrivileged: boolean; divisions: Results<Division> }

const rawBaseQuery = fetchBaseQuery({
  // CC REST API follow HATEOAS so the urls should be read from the response rather than forming manually
  baseUrl: undefined,
  prepareHeaders(headers: Headers, _api) {
    buildHeaders("", false).forEach((value, key) => {
      headers.set(key, value)
    })
    return headers
  },
  credentials: "include"
})

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error
}

/**
 * A RTK Query base query function that forms absolute urls when given a relative url.
 * This is used in cases where the href is not returned but the endpoint exists.
 */
const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const url = typeof args === "string" ? args : args.url

  // If absolute url, use that
  let result
  if (url.startsWith("http")) {
    result = await rawBaseQuery(args, api, extraOptions)
  } else {
    // Must be relative, we form using href from session object
    const operatorHref = selectOperatorUrl(api.getState() as RootState)
    const baseUrl = operatorHref ? new URL(operatorHref).origin : undefined
    if (!baseUrl) {
      return {
        error: {
          status: 400,
          statusText: "Bad Request",
          data: "Cannot form relative url"
        }
      }
    }

    const adjustedUrl = `${baseUrl}/${url.replace(/^\//, "")}`
    const adjustedArgs = typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl }
    result = await rawBaseQuery(adjustedArgs, api, extraOptions)
  }

  if (typeof result.error?.status === "number" && result.error.status >= 400 && result.error.status < 500) {
    // No point retrying for 4xx errors
    retry.fail(result.error)
  }

  return result
}

// For Test we don't need any delays
const retryOptions = process.env.NODE_ENV !== "test" ? { maxRetries: 3 } : { maxRetries: 3, backoff: async () => {} }

export const api = createApi({
  reducerPath: "api",
  baseQuery: retry(dynamicBaseQuery, retryOptions),
  tagTypes: ["Cardholders", "CardholderThumbnails", "Divisions", "OperatorSettings", "PersonalDataFieldDefinition", "SiteSettings", "Events"],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    //
    // ACCESS ZONES
    //
    getAccessZones: builder.query<Results<LinkWithIdAndName>, { url?: string }>({
      query: (args) => ({
        url: args.url,
        method: "GET"
      })
    }),

    //
    // CARDHOLDERS
    //
    getCardholders: builder.query<Results<Cardholder>, GetCardholderArgs>({
      query: (args) => {
        const { url, next, tag, ...rest } = args

        if (next) return { url: next, method: "GET" }

        const searchParams: Record<string, string | boolean | number | undefined> = {
          ...rest,
          fields: args.fields?.join(","),
          division: args.division?.join(","),
          accessZone: args.accessZone?.join(",")
        }

        return {
          url: next ?? appendQueryString(url!, searchParams),
          method: "GET"
        }
      },
      providesTags: (_result, _err, { name }) => ["Cardholders", { type: "Cardholders", id: name }]
    }),
    getCardholderThumbnails: builder.query<Results<Thumbnail>, GetCardholderThumbnailsArgs>({
      query: ({ url, cardholderIds, sourcePdfIds, maxWidth = 50, maxHeight = 50 }) => ({
        url: appendQueryString(url!, { ids: cardholderIds.join(","), sourcePdf: sourcePdfIds.join(","), maxWidth, maxHeight }),
        method: "GET"
      }),
      transformResponse: (response: Results<Thumbnail>) => {
        return {
          ...response,
          results: response.results.map((thumbnail) => ({
            ...thumbnail,
            // append the base64 tag here so we don't need to do it when its consumed
            thumbnail: thumbnail.thumbnail ? `data:image/jpeg;base64, ${thumbnail.thumbnail}` : undefined
          }))
        }
      },
      providesTags: (_result, _err, args) => args.cardholderIds.map((id) => ({ type: "CardholderThumbnails", id: id }))
    }),
    getCardholdersWithThumbnails: builder.query<Results<CardholderWithThumbnail>, GetCardholdersWithThumbnailsArgs>({
      queryFn: async (args, queryApi, _extraOptions, _fetchWithBQ): Promise<QueryReturnValue<Results<CardholderWithThumbnail>>> => {
        const { getCardholdersUrl, getCardholderThumbnailsUrl: getThumbnailsUrl, ...rest } = args
        const getCardholdersResponse = await queryApi.dispatch(api.endpoints.getCardholders.initiate({ url: getCardholdersUrl, ...rest }))
        if (getCardholdersResponse.error) {
          return { error: getCardholdersResponse.error }
        } else if (!getCardholdersResponse.data || getCardholdersResponse.data.results.length === 0) {
          return { data: { results: [] } }
        }

        let cardholderWithThumbnails = getCardholdersResponse.data as unknown as Results<CardholderWithThumbnail>

        // Thumbnails are niceties so this step is optional
        const pdfIds = selectProfileImageIds(queryApi.getState() as RootState) ?? []
        const getThumbnailsResponse = await queryApi.dispatch(
          api.endpoints.getCardholderThumbnails.initiate({
            url: getThumbnailsUrl,
            ...rest,
            cardholderIds: getCardholdersResponse.data.results.map((c) => c.id),
            sourcePdfIds: rest.sourcePdfIds ?? pdfIds
          })
        )
        if (getThumbnailsResponse.data) {
          // Combine thumbnails to cardholders
          cardholderWithThumbnails = {
            ...cardholderWithThumbnails,
            results: cardholderWithThumbnails.results.map((c) => {
              const thumbnail = getThumbnailsResponse.data?.results.find((t) => t.id === c.id)?.thumbnail
              return thumbnail ? { ...c, thumbnail } : c
            })
          }
        }

        return { data: cardholderWithThumbnails }
      },
      providesTags: (result, _err, args) => {
        return ["Cardholders", { type: "Cardholders", id: args.name }, ...(result?.results.filter((c) => c.id).map((c) => ({ type: "CardholderThumbnails" as const, id: c.id })) ?? [])]
      }
    }),
    deleteCardholder: builder.mutation<void, { url: string; id: number }>({
      query: ({ url, id }) => ({
        url: `${url}/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: (_result, _err, { id }) => ["Cardholders", { type: "Cardholders", id: id }]
    }),

    //
    // DIVISIONS
    //
    getDivision: builder.query<LinkWithIdAndName, { url?: string }>({
      query: ({ url }) => ({
        url: url,
        method: "GET"
      }),
      keepUnusedDataFor: 1000
    }),
    getDivisionsBatched: builder.query<Results<LinkWithIdAndName>, { hrefs?: string[] }>({
      async queryFn({ hrefs }, queryApi, _extraOptions, _baseQuery): Promise<QueryReturnValue<Results<LinkWithIdAndName>>> {
        if (!hrefs) return { data: { results: [] } }

        const promises = hrefs.map((href) => queryApi.dispatch(api.endpoints.getDivision.initiate({ url: href })))
        const results = await Promise.allSettled(promises)
        const divisions = results
          .filter((r) => r.status === "fulfilled")
          .filter((r) => r.value.isSuccess)
          .map((r) => r.value.data)
          .filter((div) => div !== undefined)

        if (divisions.length === 0) {
          return { error: "Failed to fetch all divisions" }
        }

        return { data: { results: divisions } }
      }
    }),
    getDivisions: builder.query<Results<Division>, { url?: string; verb?: VerbId; type?: FTItemType }>({
      query: ({ url, verb, type }) => {
        // Use the enum name as it is more readable
        const sp = {
          verb: verb !== undefined ? Object.keys(VerbId)[Object.values(VerbId).indexOf(verb)].toLowerCase() : undefined,
          type: type != undefined ? Object.keys(FTItemType)[Object.values(FTItemType).indexOf(type)].toLowerCase() : undefined
        }

        return {
          url: appendQueryString(url ?? "/api/divisions", sp),
          method: "GET"
        }
      },
      providesTags: (_result, _err, { verb, type }) => ["Divisions", { type: "Divisions", id: `verb=${verb}&type=${type}` }]
    }),
    canDoVerb: builder.query<CanDoVerbResult, { divisionsUrl?: string; verb: VerbId; type: FTItemType; filter?: (division: Division) => boolean }>({
      queryFn: async (args, queryApi, _extraOptions, _fetchWithBQ): Promise<QueryReturnValue<CanDoVerbResult>> => {
        // For 8.90+ we prefer using the /api/divisions endpoint
        const divisionsUrl = selectDivisionsUrl(queryApi.getState() as RootState)
        const version = selectCommandCentreVersion(queryApi.getState() as RootState)
        if (version && Version.greaterThanOrEqual(version, KnownCommandCentreVersion.GetDivisionsVerbAndFTItem)) {
          const response = await queryApi.dispatch(api.endpoints.getDivisions.initiate({ ...args, url: args.divisionsUrl ?? divisionsUrl?.href }))
          const isComplete = response.isError || response.isSuccess
          if (!isComplete) return { data: { isPrivileged: false, divisions: { results: [] } } }
          //if we have api error we assume they are privileged and let the REST API tell us otherwise
          if (response.isError) return { data: { isPrivileged: true, divisions: { results: [] } } }

          const divisions = response.data ?? { results: [] }
          const isPrivileged = args.filter ? divisions.results.some(args.filter) : divisions.results.length > 0
          return { data: { isPrivileged, divisions } }
        }

        // for 8.80 some features can be known to be privileged through some other url
        if (args.verb === VerbId.New && args.type === FTItemType.Cardholder) {
          return { data: { isPrivileged: !!selectCardholderAssignableDivisions(queryApi.getState() as RootState), divisions: { results: [] } } }
        }

        // else we assume they are privileged and let the REST API tell us otherwise
        return { data: { isPrivileged: true, divisions: { results: [] } } }
      },
      providesTags: (_result, _err, { verb, type }) => ["Divisions", { type: "Divisions", id: `verb=${verb}&type=${type}` }]
    }),

    //
    // EVENTS
    //
    getEvents: builder.query<ActivityCollection, GetEventsArgs>({
      query: ({ url, relatedItem, fields, previous, top }) => ({
        url: `${url}?relatedItem=${relatedItem}&previous=${previous}&top=${top}&fields=${fields.join(",")}`,
        method: "GET"
      }),
      providesTags: ["Events"]
    }),
    getEventsUpdates: builder.query<ActivityCollection, GetEventUpdateArgs>({
      query: ({ url, ts }) => ({
        url,
        method: "GET"
      })
    }),
    // OPERATOR SETTINGS
    //
    getOperatorSettings: builder.query<OperatorSettings, { url?: string; fields: OperatorSettingsName[] }>({
      query: ({ url, fields }) => ({
        url: `${url}?fields=${fields.join(",")}`,
        method: "GET"
      }),
      providesTags: (_result, _err, { fields }) => fields.map((field) => ({ type: "OperatorSettings", id: field }))
    }),
    updateOperatorSettings: builder.mutation<void, { url?: string; settings: PatchOperatorSettings }>({
      query: ({ url, settings }) => ({
        url: `${url}`,
        method: "PATCH",
        body: settings
      }),
      invalidatesTags: (_result, _err, { settings }) => Object.keys(settings).map((field) => ({ type: "OperatorSettings", id: field }))
    }),

    //
    // PERSONAL DATA FIELD DEFINITIONS
    //
    getPersonalDataFieldDefinitions: builder.query<Results<PDFItem>, { url?: string; fields?: PDFFields[] }>({
      query: ({ url, fields }) => ({
        url: appendQueryString(url ?? "/api/personal_data_fields/view", { fields: fields?.join(",") }),
        method: "GET"
      }),
      providesTags: ["PersonalDataFieldDefinition"]
    }),

    //
    // SITE SETTINGS
    //
    getSiteSettings: builder.query<SiteSettings, { url?: string; fields: SiteSettingsName[] }>({
      query: ({ url, fields }) => ({
        url: `${url}?fields=${fields.join(",")}`,
        method: "GET"
      }),
      providesTags: (_result, _err, { fields }) => fields.map((field) => ({ type: "SiteSettings", id: field }))
    }),
    updateSiteSettings: builder.mutation<void, { url?: string; settings: PatchSiteSettings }>({
      query: ({ url, settings }) => ({
        url: `${url}`,
        method: "PATCH",
        body: settings
      }),
      invalidatesTags: (_result, _err, { settings }) => Object.keys(settings).map((field) => ({ type: "SiteSettings", id: field }))
    }),
    updateUserCode: builder.mutation<void, { url: string; id: string; userCode: string }>({
      query: ({ url, id, userCode: code }) => ({
        url: `${url}/${id}`,
        method: "PATCH",
        body: { userCode: code }
      })
    })
  })
})

export const {
  useCanDoVerbQuery,
  useDeleteCardholderMutation,
  useGetDivisionQuery,
  useGetDivisionsQuery,
  useGetAccessZonesQuery,
  useGetEventsQuery,
  useLazyGetAccessZonesQuery,
  useLazyGetDivisionsQuery,
  useLazyGetDivisionsBatchedQuery,
  useLazyGetCardholdersWithThumbnailsQuery,
  useLazyGetEventsUpdatesQuery,
  useGetPersonalDataFieldDefinitionsQuery,
  useLazyGetPersonalDataFieldDefinitionsQuery,
  useGetSiteSettingsQuery,
  useLazyGetSiteSettingsQuery,
  useUpdateSiteSettingsMutation,
  useGetOperatorSettingsQuery,
  useLazyGetOperatorSettingsQuery,
  useUpdateOperatorSettingsMutation,
  useUpdateUserCodeMutation
} = api
